@import "../bootstrap/scss/bootstrap.scss";

.end_date {
  padding: 5px 39px 5px 39px !important;
}

a {
  text-decoration: none;
}

.button_style {
  background: unset !important;
  border: none !important;
}

.profile_img {
  border-radius: 13px;
  border: none;
  outline: none;
  width: 48px !important;
  height: 47px !important;
  margin-top: -1px !important;
}

.unassigned {
  background: #eee;
  padding: 10px !important;
  border-radius: 6px;
}

.task_items_member {
  // width: 5.2%;

  .img {
    width: 24px !important;
    height: 24px !important;
  }
}

.unassigned_image {
  background: #f4f5f7;
  text-align: center;
  border-radius: 6px;
  // padding: 2px 9.2px;
  width: 26px;
  height: 26px;

  img {
    height: 15px;
  }
}

.select-follower-bor {
  margin-right: -6px !important;
  height: unset !important;

  img {
    border: 1px solid #fff !important;
    width: 26px !important;
    height: 26px !important;
  }
}

.no-progress {
  background: #f0f0f0;
  color: #7a7a7a;
}

.task_items_list.kaban-story {
  width: 24px;
  background: #F4F5F7;
  margin-left: 7px;
  padding: 4px;
}

.task_items_list.comment {
  .icon {
    background-image: url("../images/msg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
  }
}

.task_items_list {
  padding: 5px;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.task_items_list.current-date {
  background: #e9f2ff !important;
  text-align: center;

  p {
    color: #2684ff !important;
  }
}

.task_items_list.due-date {
  background: #ffcece !important;
  text-align: center;

  p {
    color: #f0142f !important;
  }
}

.task_items_priority {
  width: 16%;
}

.task_items_progress {
  width: 19%;
}

.task_items_date {
  width: 22%;
}

.task_items_message {
  width: 9%;
}

.task_items_points {
  width: 5.2%;
}

.done {
  background: #e9f6ec;
  color: #28a745;
}

.in-progress {
  background: #fff6e5;
  color: #ffab00;
}

.testing {
  // background: #ffbaa473;
  background: #e9f2ff;
  color: #2684ff;
}

.none-priority {
  background: #eee;
  color: #7a7a7a;
}

.p-x-y {
  padding-left: 18px !important;
  padding-right: 18px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pl-14 {
  padding-left: 14px;
}

#fullLogo {
  display: black;
}

#logoIcon {
  display: none;
  width: 60px;
}

@media (max-width: 1195px) {
  #fullLogo {
    display: none;
  }

  #logoIcon {
    display: block;
    width: 41px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.close-drawer p {
  cursor: pointer;
}

.global-search-main {
  @media (max-width: 900px) {
    overflow: unset !important;
  }
}

.global-search-input {
  width: 95% !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.project_navbar {
  .page-navbar {
    position: fixed;
    width: 100%;
    height: inherit;
    font-weight: 500;
    background: #fff;
    z-index: 99;
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0 0 9px rgb(26 36 71 / 10%);

    .navbar-brand__my {
      display: flex;
      align-items: center;
      width: 14.5%;

      @media (max-width: 1400px) {
        width: 14%;
      }

      .navbar-brand {
        margin-right: 0;
      }

      @media (max-width: 1195px) {
        width: 5.5%;
      }

      @media (max-width: 902px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    .navbar-collapse__my {
      display: flex !important;
      flex-basis: auto;

      .project-ul {
        width: 100%;
        position: relative;

        li a {
          color: #707070;
          font-size: 14px;
          cursor: pointer;
          padding-left: 15px;
          padding-right: 15px;
          font-weight: 600;

          @media (max-width: 1324px) {
            padding-left: 10px;
            padding-right: 10px;
          }

          @media (max-width: 919px) {
            padding-left: 8px;
            padding-right: 8px;
          }

          &:hover {
            color: $blue !important;
          }
        }

        .QA-nav-item {
          padding: 0px 10px !important;

          @media (max-width: 1324px) {
            padding: 0px !important;
          }
        }

        li.active a {
          color: #0052cc;
        }

        .project_search_li {
          padding-left: 28px;
          width: 100%;

          .input-search__navbar {
            outline: 0;
            border: 0;
            width: 100%;
            padding-left: 10px;
          }
        }

        .project-nav {
          width: auto;

          .input-search__navbar {
            outline: 0;
            border: 0;
            padding-left: 10px;
          }

          .search {
            cursor: pointer;

            &:hover {
              color: #0052cc;
              cursor: pointer;
            }
          }

          .fa-xmark {
            position: absolute;
            right: 11%;

            @media (max-width: 1620px) {
              right: 8%;
            }

            @media (max-width: 1500px) {
              right: 6%;
            }

            @media (max-width: 1450px) {
              right: 7.1%;
            }

            @media (max-width: 1400px) {
              right: 8.8%;
            }

            @media (max-width: 1350px) {
              right: 13.4%;
            }

            @media (min-width: 1600px) {
              right: 22%;
            }
          }

          .search-icon {
            width: 15px;
            height: 15px;
            background-image: url("../images/SearchIcon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: 19px;
          }

          .bar-icon_btn {
            border: 1px solid #ccc;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .nav-right {
        .show.dropdown .option-icon {
          background: unset !important;
          color: #0052cc;
          cursor: pointer;

          .dropdown-menu.show {
            transform: translate(-23px, 49px) !important;
            border-radius: 10px !important;
            min-width: 130px !important;

            .dropdown-item {
              background: none;
              font-size: 14px;
            }
          }

          &:hover {
            color: #0052cc;
          }
        }

        .nav-set {
          text-align: center;
          align-items: center;
          display: flex;

          .show.dropdown .option-icon {
            background: unset !important;
            color: #0052cc;
            cursor: pointer;

            .dropdown-menu.show {
              transform: translate(-48px, 46px) !important;
              border-radius: 10px !important;
            }

            &:hover {
              color: #0052cc;
            }
          }

          .option-icon {
            &:hover {
              background: unset !important;
            }
          }

          .option-icon .dropdown-menu {
            top: 52px !important;
            right: 8px !important;
          }
        }
      }
    }

    .input-search__navbar {
      outline: 0;
      border: 0;
    }
  }
}

.option-icon {
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 3px;
    color: #7d7070;
  }

  &:hover {
    cursor: pointer;
    background: #e5edfa !important;

    svg {
      color: #0052cc;
    }
  }
}

.bookmark-icon {
  width: 19px;
  height: 25px;
  color: #707070;

  &:hover {
    color: $blue;
    cursor: pointer;
  }
}

.nocitification-icon {
  width: 23px;
  height: 25px;
  color: #707070;

  &:hover {
    cursor: pointer;
    color: $blue;
  }
}

.user-profile {
  width: 48px;
  height: 48px;
  // background-image: url('../images/UserImage.png');
  background-color: #a6a6a6e0;
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
}

.nav-hr {
  padding: 0;
  margin: 0;
  color: #ccc;
}

/* Custom media query for 1290px width */
// @media (max-width: 1290px) {
//   .navbar-expand-lg .navbar-collapse {
//     display: none !important;
//   }

//   .navbar-collapse.show {
//     display: block !important;
//   }

//   .navbar-expand-lg .navbar-toggler {
//     display: block;
//   }
// }
.project_navbar .page-navbar {
  .d-none {
    display: none !important;
  }
}

.add-dis {
  display: block !important;
}

// Sidebar
.wrapper {
  width: 100%;
  height: fit-content;
  margin-top: 70px;

  @media (max-width: 1195px) {
    margin-top: 59px;
  }
}

.sidebar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 15%;
  transition: 0.1s ease-in-out;

  .content {
    position: fixed;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: -webkit-fill-available;
    border-right: 1px solid #eeeeee;
    box-shadow: 0 0 35px rgb(26 36 71 / 10%);

    .bottom_navbar {
      border-top: 2px solid #eeeeee !important;
      padding-top: 8px;
    }

    .top-items {
      margin-top: 8px;
    }

    ul {
      width: 100%;
    }
  }

  ul a {
    text-decoration: none;
    color: unset;
  }

  .active {
    background: #e5edfa;
    border-radius: 10px;

    .icons {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }

    a {
      color: #0052cc !important;

      &:hover {
        color: #0052cc !important;
      }
    }
  }

  .icons {
    margin-right: 18px;
    color: #707070;
  }

  ul {
    padding: 0;
  }

  li {
    padding: 18px 9px;
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 48px;
    margin-left: 8px;
    margin-right: 8px;

    a {
      color: #707070 !important;
      font-weight: 600;
      font-size: 14px !important;
    }

    &:hover {
      cursor: pointer;
      color: $blue;

      a {
        color: #0052cc !important;
      }

      .icon-text {
        color: $blue;
      }

      .icons {
        color: $blue;
      }
    }
  }

  .collapse_padding {
    padding-left: 14px !important;
  }

  .icon-text {
    font-size: 14px;
    color: #707070;
  }

  @media (max-width: 840px) {
    width: 84px;
    // width: 10%;
    transition: all 0.3s;

    li {
      padding-left: 36px;
      transition: all 0.3s;
    }

    .icon-text {
      display: none;
      transition: all 0.3s;
    }
  }

  @media (max-width: 400px) {
    width: 20%;
  }
}

.dashboard-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Dashboard.svg");
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    fill: #0052cc !important;
  }
}

.project-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Project_icon.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.task-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/task.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bugs-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Bugs.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.sprint-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Sprints.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.conversation-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/conversation.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.wikies-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Wikies.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.team-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Team.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.setting-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Setting.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.logout-icon {
  width: 15px;
  height: 15px;
  background-image: url("../images/Logout.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hidden__plugin_property {
  .rdw-text-align-wrapper {
    display: none;
  }

  .rdw-emoji-wrapper {
    display: none;
  }
}

.sidebar {
  height: 100vh;
  transition: width 0.3s ease;

  .s-content {
    position: fixed;
    height: -webkit-fill-available;
    width: inherit;
  }

  .sidebar_toggle {
    z-index: 1;
    bottom: 86px;
    height: 35px;
    background: #e5edfa;
    border-radius: 0% 50% 50% 0 !important;
    left: auto !important;
    right: -18px;
    width: 18px;
    padding: 5px 3px;

    &:hover {
      background: #e5edfa !important;
    }

    svg {
      color: #0052cc !important;
    }
  }
}

.sidebar.sidebar_collapse {
  width: 60px;
}

.collapse-button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

/* Add your other styles for top and bottom items here */

/* Media query for small screens */
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.sidebar_expand {
    width: 200px;
  }
}

.sidebar_toggle {
  padding: 7px 12px;
  border-radius: 50%;
  transition: left 0.3s ease;
  cursor: pointer;
  position: absolute;
  left: 1.3%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar_toggle:hover {
  background-color: rgb(226, 237, 250);
  border-radius: 50%;
}

.sidebar_list_item {
  .active {
    img {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
    }
  }

  &:hover {
    img {
      filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);

      &:hover {
        filter: invert(29%) sepia(98%) saturate(7500%) hue-rotate(209deg) brightness(91%) contrast(101%);
      }
    }
  }
}

.avatar__container {
  width: 64px;
  height: 64px;
  margin: auto;
}

.avatar__border {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #979797;
}

.setting_drop_ul {
  padding: 10px 7px !important;

  .setting_drop_item {
    &:hover {
      background: #f4f5f6;
      border-radius: 10px;
    }
  }
}

.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 1000;
}